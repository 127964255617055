<cjm-layout-container layoutType="split-equal" asidePosition="right" alignItems="start">
	<ng-container main>
		<h1 class="c-vloket-header c-vloket-header--h2">
			<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
				{{ redirectI18nKeys.Disconnected.Title | translate }}
			</span>
		</h1>

		<div class="u-margin-bottom-xl">
			{{ redirectI18nKeys.Disconnected.Text | translate }}
		</div>

		<div class="p-offline__buttons">
			<cjm-button
				[classes]="buttonClasses.LinkButton"
				[title]="this.redirectI18nKeys.Disconnected.Button.Title | translate"
				(handleClick)="retryStatus()"
			>
				{{ this.redirectI18nKeys.Disconnected.Button.Text | translate }}
			</cjm-button>
		</div>
	</ng-container>

	<div class="p-offline__visual" asideRight>
		<cjm-img src="img/offline.svg" />
	</div>
</cjm-layout-container>
