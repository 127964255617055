import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { Observable, takeUntil, tap } from 'rxjs';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { OnDestroyComponent } from '@cjm/shared/types';

import { RedirectI18nKeys } from '../../../i18n';
import { ErrorComponentInput } from '../../components';
import { ErrorComponent } from '../../components/error/error.component';

import { NotFoundPageTypes } from './not-found.page.types';

@Component({
	templateUrl: './not-found.page.html',
	styleUrls: ['./not-found.page.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ErrorComponent]
})
export class NotFoundPageComponent extends OnDestroyComponent implements OnInit {
	@HostBinding('class.p-not-found') private readonly hasPageClass: boolean = true;

	public readonly i18nKeys: typeof RedirectI18nKeys = RedirectI18nKeys;
	public readonly notFoundPageTypes: typeof NotFoundPageTypes = NotFoundPageTypes;

	public pageType: `${NotFoundPageTypes}` = NotFoundPageTypes.NOT_FOUND;
	public pageData: ErrorComponentInput;

	private readonly clearQueryParams$: Observable<unknown> = this.activatedRoute.queryParams.pipe(
		tap(() => {
			const queryParams: Params = {};

			this.router.navigate([], {
				relativeTo: this.activatedRoute,
				queryParams,
				queryParamsHandling: '',
				preserveFragment: false
			});
		}),
		takeUntil(this.destroyed$)
	);

	constructor(
		private i18nService: I18nService,
		private activatedRoute: ActivatedRoute,
		private router: Router
	) {
		super();
	}

	public ngOnInit(): void {
		// Set page type and page data
		this.activatedRoute.data
			.pipe(
				tap((data) => {
					this.pageType = data['pageType'];
				}),
				tap(() => {
					const routeState = this.router?.lastSuccessfulNavigation?.extras?.state;

					if (this.pageType === NotFoundPageTypes.NOT_FOUND) {
						this.pageData = {
							title: this.i18nService.getTranslation(this.i18nKeys.NotFound.Title),
							subtitle: this.i18nService.getTranslation(this.i18nKeys.NotFound.Subtitle),
							text: this.i18nService.getTranslation(this.i18nKeys.NotFound.Text),
							visual: 'img/niet-gevonden.svg'
						};
					}

					if (this.pageType === NotFoundPageTypes.SOMETHING_WENT_WRONG) {
						this.pageData = {
							title: this.i18nService.getTranslation(this.i18nKeys.SomethingWentWrong.Title),
							subtitle: this.i18nService.getTranslation(this.i18nKeys.SomethingWentWrong.Subtitle),
							text: this.i18nService.getTranslation(this.i18nKeys.SomethingWentWrong.Text),
							visual: 'img/server-fout.svg',

							button: {
								title: this.i18nService.getTranslation(this.i18nKeys.SomethingWentWrong.Button.Title),
								text: this.i18nService.getTranslation(this.i18nKeys.SomethingWentWrong.Button.Text),
								link: routeState?.callbackUrl ? routeState.callbackUrl : '',
								queryParams: routeState?.callbackUrlQueryParams
									? routeState.callbackUrlQueryParams
									: null
							}
						};
					}

					if (this.pageType === NotFoundPageTypes.INVITATION_ERROR) {
						this.pageData = {
							title: this.i18nService.getTranslation(this.i18nKeys.InvitationError.Title),
							subtitle: this.i18nService.getTranslation(this.i18nKeys.InvitationError.Subtitle),
							text: this.i18nService.getTranslation(this.i18nKeys.InvitationError.Text),
							visual: 'img/uitnodiging-error.svg'
						};
					}
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Clear query parameters on init
		if (this.activatedRoute.snapshot.url.toString() === VLoketAppRoutePaths.NotFound) {
			this.clearQueryParams$.subscribe();
		}
	}
}
