@if (pageData) {
	<cjm-layout-container class="c-error" layoutType="split-equal" asidePosition="right" alignItems="start">
		<ng-container main>
			<h1 class="c-vloket-header c-vloket-header--h6">
				{{ pageData.subtitle }}
			</h1>
			<h2 class="c-vloket-header c-vloket-header--h2">
				<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
					{{ pageData.title }}
				</span>
			</h2>
			<div class="u-margin-bottom-xl">
				<p [innerHTML]="pageData.text"></p>
			</div>
			<div class="c-error__buttons">
				@if (pageData?.button?.link) {
					<a
						[routerLink]="pageData.button.link"
						[queryParams]="pageData.button.queryParams"
						[class]="buttonClasses.LinkButtonOutline"
						[title]="pageData.button.title"
					>
						{{ pageData.button.text }}
					</a>
				}
				<a
					routerLink="/"
					[class]="buttonClasses.LinkButton"
					[title]="this.i18nKeys.NotFound.Button.Title | translate"
				>
					{{ this.i18nKeys.NotFound.Button.Text | translate }}
				</a>
			</div>
		</ng-container>
		<div class="c-error__visual" asideRight>
			<cjm-img [src]="pageData.visual" />
		</div>
	</cjm-layout-container>
}
