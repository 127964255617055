import { NgModule } from '@angular/core';

import { SharedCoreModule } from '@cjm/shared/core';

import { StatusProviderService, StatusService } from './services';

@NgModule({
	imports: [SharedCoreModule],
	providers: [StatusProviderService, StatusService]
})
export class StatusModule {}
