import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_ERROR_HANDLER } from '@cjm/shared/authentication/auth';
import { CJMHttpClientService } from '@cjm/shared/core';
import { VLoketEndpoints } from '@cjm/shared/endpoint';

import { AppStatusEntity, AppStatusResultEntity } from '../../interfaces';

@Injectable()
export class StatusProviderService {
	constructor(private readonly httpClient: CJMHttpClientService) {}

	public getApplicationStatus(): Observable<AppStatusEntity> {
		return this.httpClient
			.get<AppStatusResultEntity>(
				VLoketEndpoints.Status.Status(),
				{},
				false,
				new HttpContext().set(SERVER_ERROR_HANDLER, false)
			)
			.pipe(
				map((result) => {
					// Iben: If the call succeeds, the application is available
					return {
						isActive: !result.onderhoudsmodus,
						message: result.onderhoudsmodusBericht,
						features: result.features?.elementen || []
					};
				})
			);
	}
}
