import { Routes } from '@angular/router';
import { provideWithTranslations, TranslationLoaderGuard } from '@studiohyperdrive/ngx-i18n';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';

import { RedirectsTranslationLoader } from '../i18n/loaders';

import { AuthenticationFailedGuard, isAuthenticationSuccessGuard, MaintenanceGuard, OfflineGuard } from './guards';
import {
	AuthenticationFailedPageComponent,
	MaintenancePageComponent,
	NoResourcesComponent,
	NotFoundPageComponent,
	NotFoundPageTypes,
	OfflinePageComponent,
	StopPageComponent
} from './pages';

export const RedirectsRoutes: Routes = [
	provideWithTranslations(
		{
			path: VLoketAppRoutePaths.Empty,
			canActivate: [TranslationLoaderGuard],
			children: [
				{
					path: VLoketAppRoutePaths.Empty,
					pathMatch: 'full',
					redirectTo: VLoketAppRoutePaths.Maintenance
				},
				{
					path: VLoketAppRoutePaths.AuthenticationFailed,
					canActivate: [AuthenticationFailedGuard],
					canDeactivate: [isAuthenticationSuccessGuard],
					component: AuthenticationFailedPageComponent
				},
				{
					path: VLoketAppRoutePaths.Maintenance,
					canActivate: [MaintenanceGuard],
					component: MaintenancePageComponent
				},
				{
					path: VLoketAppRoutePaths.NoResources,
					canActivate: [],
					component: NoResourcesComponent
				},
				{
					path: VLoketAppRoutePaths.Offline,
					canActivate: [OfflineGuard],
					component: OfflinePageComponent
				},
				{
					path: VLoketAppRoutePaths.NotFound,
					component: NotFoundPageComponent,
					data: { pageType: NotFoundPageTypes.NOT_FOUND }
				},
				{
					path: VLoketAppRoutePaths.ServerError,
					component: NotFoundPageComponent,
					data: { pageType: NotFoundPageTypes.SOMETHING_WENT_WRONG }
				},
				{
					path: VLoketAppRoutePaths.InvitationError,
					component: NotFoundPageComponent,
					data: { pageType: NotFoundPageTypes.INVITATION_ERROR }
				},
				{
					path: VLoketAppRoutePaths.Stop,
					component: StopPageComponent
				}
			]
		},
		RedirectsTranslationLoader
	)
];
